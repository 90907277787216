import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import OriginDestinationInputs from './OriginDestinationInputs';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { formatThousands } from "../utils/Utils";

const GetOriginDestinationDistance = ({sendDataToParent, idInput,origenTerceros, questionTitle}) => {

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const [distance, setDistance] = useState(null);
  const [distanceInputsData, setDistanceInputsData] = useState('');
  const [factor, setFactor] = useState('');

  const [isLoading, setIsLoading] = useState(null);
  const [message, setMessage] = useState('');

  function handleDataFromChild(data) {

    setDistanceInputsData({
      originLatLng: data.origin.coordinates.lat + "," + data.origin.coordinates.lng,
      destinationLatLng: data.destination.coordinates.lat + "," + data.destination.coordinates.lng,
      numberOfTrips: data.numberOfTrips,
      roundTrip: data.roundTrip,
      originName: data.origin.name,
      destinationName: data.destination.name
    });

  }

  useEffect(() => {
    let factor = distanceInputsData.numberOfTrips;
    if (distanceInputsData.roundTrip) {
      factor = factor * 2;
    }
    setFactor(factor);

  }, [distanceInputsData])



  useEffect(() => {
    let inputDistanceElement = document.getElementById(idInput);
    let distanceFinal = ((distance * factor) / 1000).toFixed(2);

    if (!Number.isNaN(distanceFinal) && distanceFinal > 0) {
      sendDataToParent({
        distance: distanceFinal,
        ...distanceInputsData
      });
      inputDistanceElement.value = distanceFinal;
    }

  }, [distance, factor])

  useEffect(() => {
    if (distanceInputsData != '') {

      const titleLowerCase = questionTitle.toLowerCase();
      const [originLat, originLng] = distanceInputsData.originLatLng.split(",");
      const [destinationLat, destinationLng] = distanceInputsData.destinationLatLng.split(",");

      if (titleLowerCase.includes("aéreo")) {
        getAirRouteDistance(originLat,originLng,destinationLat,destinationLng);
      } else if (titleLowerCase.includes("terrestre") || titleLowerCase.includes("carretera") || titleLowerCase.includes("ferroviario")) {
        getDistance();
      } else if (titleLowerCase.includes("marítimo")) {
        getSeaRouteDistance(originLat,originLng,destinationLat,destinationLng);
      } else {
        getDistance();
      } 
    }
  }, [distanceInputsData])

  const getAirRouteDistance = (originLat,originLng,destinationLat,destinationLng) => {
    try {
      setIsLoading(true);
      const point1 = new window.google.maps.LatLng(originLat, originLng);
      const point2 = new window.google.maps.LatLng(destinationLat, destinationLng);
      const straightLineDistance = window.google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
      setDistance(airRouteCorrection(straightLineDistance));
    } catch (error) {
      console.error('Error calculating air distance')
    }  finally {
      setIsLoading(false);
    }
  }

  const airRouteCorrection = (distance) => {
    if (distance < 550000) {
      return distance+50000;
    } else if (distance > 550000 && distance < 5500000) {
      return distance+100000;
    } else if (distance > 5500000) {
      return distance+125000;
    }
  }

  const getSeaRouteDistance = async (originLat,originLng,destinationLat,destinationLng) => {
    setIsLoading(true);
    try {
      let url = `${process.env.REACT_APP_PY_SEA_DISTANCE}/${originLng}/${originLat}/${destinationLng}/${destinationLat}`;
      const response = await axios.get(url, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = response.data;
      if (response.status == "200" && data.length) {
        const seaDistance = data.length;
        setDistance(seaDistance);
      } else {
        console.error('Error in API response');
      }
    } catch (exception) {
      console.error("Error making API request:", exception);
    } finally {
      setIsLoading(false);
    }
  }

  const getDistance = async () => {
    setIsLoading(true);
    setMessage('');
    try {
      const response = await axios.get(`${process.env.REACT_APP_ROUTES_DISTANCE}?origin=${distanceInputsData.originLatLng}&destination=${distanceInputsData.destinationLatLng}`);
      const data = response.data;
     
      if (data.routes && data.routes.length > 0) {
        const distanceText = data.routes[0].legs[0].distance.value;
        setDistance(distanceText);
      } else {
        console.error('Error in API response');
      }
      if (data.status === 'ZERO_RESULTS') {
        setMessage(t("footPrint.errorGoogleDistancia"))
      } else {
        setMessage('')
      }
    } catch (error) {
      console.error('Error making API request:', error)
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div>

      <OriginDestinationInputs origenTerceros={origenTerceros} sendDataToParent={handleDataFromChild} isLoading={isLoading} />

      <div className='text-center mt-4'>
        {message !== '' && <h1>{message}</h1>}
        {distance && message === '' && <><h1>{t("footPrint.distancia")}:</h1>  <b>{formatThousands((distance * factor) / 1000)} km</b></>}
      </div>

    </div>
  );
};

export default GetOriginDestinationDistance;