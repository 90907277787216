import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { IdiomContext } from "../../../context/createcontext";
import folder from '../../../images/folder2.svg';
import UserService from "../../../services/UserService";
import GoogleDistance from './GetOriginDestinationDistance';
import ModalTableSelectionConcept from "./ModalTableSelectionConcept";
import Freecurrencyapi from "@everapi/freecurrencyapi-js";

function InputsComponent({
  questBlock,
  idCalculation,
  addAnswer1,
  isButtonDisabled,
  inputData,
  showSendButton,
  colLength,
  status,
  year,
  orgName,
  thirdPartyForm,
  userId
}) {



  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const [defaultValue, setDefaultValue] = useState(null);
  const [defalultValueDate, setDefaultValueDate] = useState(null);
  const [submitBtnState, setSubmitBtnState] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOthers, setSelectedOthers] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [googleDistanceModal, setGoogleDistanceModal] = useState({ view: false, idInput: null });
  const [savedAnswer, setSavedAnswer] = useState({
    data: [],
    isFetching: false,
  });

  const [conceptSelectionTableView, setConceptSelectionTableView] = useState({ view: false, idInput: null });
  const [selectedConceptFromTableView, setSelectedConceptFromTableView] = useState();
  const [selectKey, setSelectKey] = useState(0);
  const [sedes, setSedes] = useState();
  const [selectedOrgBuildingCountryId, setSelectedOrgBuildingCountryId] = useState();

  const [isOpen, setIsOpen] = useState(true);
  const currencies = ["EUR", "USD", "MXN"];
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]); 
  const freecurrencyapi = new Freecurrencyapi(`${process.env.REACT_APP_FREE_CURRENCY_API_KEY}`);
  const expenseQuestions = [59, 68, 69, 70, 71, 86, 87];

  const changeCurrency = (e) => {
    setSelectedCurrency(e.target.value)
  }

  const getYesterdayDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
      boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
      '&:hover': {
        backgroundColor: 'var(--airco2-green-color)',

      },
      color: state.isSelected ? 'white' : provided.color,
    }),
  };

  //Si cambia de pregunta
  useEffect(() => {
    setSelectedOption(null);
    setSelectedOthers(false);
  }, [questBlock])

  useEffect(() => {
    setSubmitBtnState(false)
  }, [isButtonDisabled]);

  useEffect(() => {
    const objetoTransformado = {};

    inputData.map(inputObj => {
      const input = inputObj.input;
      const options = input.options;

      if (options != undefined && options.length === 1) {
        for (const item of options) {
          objetoTransformado[input.inputOptions.toLowerCase()] = item.id;
        }
      }


    });
    setFormSavingData({ ...objetoTransformado });

    setSedes(inputData.find(input => input.input.inputOptions == "SEDES")?.input.options)
  }, [inputData]);


  const handleSecondSelectDisplay = (option) => {
    if (option.hijos !== undefined) {
      setSelectedValue(null)
      setSelectedOption(option.hijos);
    }
  };

  const [formSavingData, setFormSavingData] = useState({});
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const isCheckbox = type === 'checkbox';

    if ((questBlock.data.id === 11 || questBlock.data.id === 14) && name.toLowerCase() === "quantity2") { //ROAD DIST Y SUPP
      if (value !== "") {
        setSelectedOthers(true);
      } else {
        setSelectedOthers(false);
        setFormSavingData(prevState => {
          const newState = { ...prevState };
          if ('quantity0' in newState) {
            delete newState.quantity0;  // Elimina la clave 'quantity0'
            document.getElementById("input-quantity0").value = "";
          }
          return newState;
        });
      }
    }

    setFormSavingData((prevState) => ({
      ...prevState,
      [name.toLowerCase()]: isCheckbox ? e.target.checked : value,
    }));
  }

  const handleSelectChange = (e, inputName) => {
    const { value } = e;
    if (inputName === "CONCEPT") {
      if (e.label == "Otros") {
        setSelectedOthers(true);
      } else {
        setSelectedOthers(false);
        setFormSavingData(prevState => {
          const newState = { ...prevState };
          if ('quantity0' in newState) {
            delete newState.quantity0;  // Elimina la clave 'quantity0'
            document.getElementById("input-quantity0").value = "";
          }
          return newState;
        });
      }
    }
    setFormSavingData((prevState) => ({
      ...prevState,
      [inputName.toLowerCase()]: value,
    }));

    //En Concumo de Electricidad, guardar el countryId de la sede seleccionada para filtrar las comercializadoras y boorar el concept del formSavingData si habia alguno seleccionado
    if (questBlock.data.id == 9 &&  inputName == "SEDES") {
      let newSelectedOrgBuildingCountryId = sedes.find(sede => sede.id == value)?.countryId;
      setSelectedOrgBuildingCountryId(newSelectedOrgBuildingCountryId);
      if(formSavingData.concept != undefined && selectedOrgBuildingCountryId != newSelectedOrgBuildingCountryId ){
        setFormSavingData(prevState => {
          const newState = { ...prevState };
            delete newState.concept;
          return newState;
        });
        //Refresh del select de CONCEPT
        setSelectKey(prevKey => prevKey + 1);
      }
    }

  }

  const handleSelectChangeSecondSelect = (e, inputName) => {
    const { value } = e;
    setFormSavingData((prevState) => ({
      ...prevState,
      [inputName.toLowerCase()]: value,
    }));
  }

  //Handle que hace la llamada para guardar la linea en BBDD
  const handlerSubmit = async(e) => {
    e.preventDefault();
    setSubmitBtnState(true);
    formSavingData["question"] = parseInt(questBlock?.data.id, 10);
    formSavingData.registration_date = formSavingData.registration_date ? formSavingData.registration_date : defalultValueDate
    formSavingData.organizationBuilding = formSavingData.sedes != undefined ? formSavingData.sedes : formSavingData.organizationBuilding;
    delete formSavingData.sedes;

    let dataToSave = { ...formSavingData };

    if (expenseQuestions.includes(questBlock.data.id)) {
      let exchangeRate;
      if(selectedCurrency !== 'EUR'){
        const yesterdaytDate = getYesterdayDate();
        try {
          const response = await freecurrencyapi.historical({
            date: dataToSave.registration_date > yesterdaytDate ? yesterdaytDate : dataToSave.registration_date,
            base_currency: selectedCurrency,
            currencies: 'EUR',
          });
  
          const dateKey = Object.keys(response.data)[0];
          exchangeRate = response.data[dateKey]['EUR'];
  
          if (exchangeRate !== undefined) {
            dataToSave.observations = dataToSave.observations === undefined
              ? `${dataToSave.quantity} ${selectedCurrency}`
              : `${dataToSave.observations} ${dataToSave.quantity} ${selectedCurrency}`;
            
              let newValue = dataToSave.quantity * exchangeRate;
              dataToSave.quantity = newValue.toString();
          }
  
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
          return;
        }
      }
    }

    const fetchSavedAnswer = async () => {

      const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer`;
      try {
        setSavedAnswer({ savedAnswer: savedAnswer.data, isFetching: true });
        const response = await axios.post(url, dataToSave, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${UserService.getToken()}`
          }
        });
        setSavedAnswer({ data: response.data, isFetching: false });
        addAnswer1(response.data);
      } catch (exception) {
        setSavedAnswer({ data: [], isFetching: false });
      }
    }
    fetchSavedAnswer();
  }

  const handleModalDistanceCalculation = () => {
    setGoogleDistanceModal({ view: true, idInput: `quantity-distance-input` });
  }

  const handleConceptTableView = (idInput) => {
    setConceptSelectionTableView({ view: true, idInput: idInput });
  }

  const updateModalTableView = (props) => {
    setConceptSelectionTableView({ view: false, idInput: null });
  }

  const updateSelectedConceptTableView = (selectedRowData) => {
    setSelectedConceptFromTableView(selectedRowData)
    setSelectKey(prevKey => prevKey + 1);
  }


  const handleTableViewDefaultValue = (options, inpuOptions) => {

    if (inpuOptions == "CONCEPT") {

      if (selectedConceptFromTableView != undefined) {

        let index = options.findIndex((element) => element.name.includes(selectedConceptFromTableView.select2Name));
        let selected = options[index];
        let selectedValueMainSelect = {
          label: selected.name,
          value: selected?.id,
          hijos: selected?.combustible
        };

        handleSelectChange(selectedValueMainSelect, "CONCEPT");

        if (selectedConceptFromTableView.select3Name != undefined) {

          let index = selectedValueMainSelect.hijos.findIndex((element) => element.name == selectedConceptFromTableView.select3Name);
          let selectedChild = selectedValueMainSelect.hijos[index];

          let selectedValuesSecondarySelect = {
            value: selectedChild.id,
            label: selectedChild.name
          };

          if (selectedValueMainSelect.hijos !== undefined) {
            setSelectedValue(selectedValuesSecondarySelect);
            setSelectedOption(selectedValueMainSelect.hijos);
          }
          handleSelectChangeSecondSelect(selectedValuesSecondarySelect, "concept");
        }
        return (selectedValueMainSelect);
      } else {
        if (options.length === 1) {
          //Guardar CONCEPT id si solo hay una opcion (para cambio de sedes en electricas, de normal se pone automatico al cargar la pregunta con un useEffect)
          if(formSavingData.concept == undefined){
            setFormSavingData((prevState) => ({
              ...prevState,
              [inpuOptions.toLowerCase()]: options[0].id,
            }));
          }
          return ({ label: options[0].name, value: options[0].id })
        } else {
          return null;
        }
      }
    } else {
      if (options.length === 1) {
        return ({ label: options[0].name, value: options[0].id })
      } else {
        return null;
      }
    }

  }

  const renderTooltipDistancia = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("footPrint.calcularDistancia")}
    </Tooltip>
  );

  const renderTooltipFecha = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("footPrint.noDate")}
    </Tooltip>
  );

  const handleDistanceFromGoogle = (data) => {
    if (data != undefined) {
      setFormSavingData((prevState) => ({
        ...prevState,
        ["quantity"]: data.distance,
        ["destination"]: data.destinationName,
        ["origin"]: data.originName
      }));
    }
  }

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setFullYear(year);
    const formattedDate = currentDate.toISOString().split('T')[0];
    setDefaultValueDate(formattedDate);
  }, [year]);


  const [mostrarInfo, setMostrarInfo] = useState({});
  const toggleInfo = (inputId) => {
    setMostrarInfo(prevState => ({
      ...prevState,
      [inputId]: !prevState[inputId]
    }));
  };




  return (
    <div id="firstComponent" className='mb-4 mt-2'>

      <Modal show={googleDistanceModal.view}>
        <ModalHeader className='sec-neutro-background'>
          <ModalTitle className="font-semibold sec-grey-text">{t("footPrint.calcularDistancia")}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div>
            <GoogleDistance sendDataToParent={handleDistanceFromGoogle} idInput="quantity-distance-input" questionTitle={questBlock.data.title} />
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-center">
          <button className="btn-sm btn-cancel rounded-lg" onClick={() => setGoogleDistanceModal({ view: false, idInput: null })}>
            {t("fpThird.cerrar")}
          </button>
        </ModalFooter>
      </Modal>

      {conceptSelectionTableView.view &&
        <ModalTableSelectionConcept
          conceptSelectionTableView={conceptSelectionTableView}
          updateModalTableView={updateModalTableView}
          idCalculation={idCalculation}
          updateSelectedConceptTableView={updateSelectedConceptTableView}
          idQuestion={questBlock.data.id} />
      }
      {questBlock?.data?.hasExternalQuestionnaire && status !== "FINALIZED" &&
        <button disabled={!thirdPartyForm} type='button' className='my-3 text-center text-md w-full' onClick={() => navigate(`/footprint/formContacts/${idCalculation}`, { state: { data: { year: year, orgName: orgName, status: status, thirdPartyForm: thirdPartyForm, userId: userId } } })}>
          <div className="bg-white rounded-sm  border-airco2 py-3 px-2" >
            <div className=" flex flex-col lg:flex-row justify-between items-center">
              <div className="txt text-md " style={{ flex: "3" }}>
                {thirdPartyForm && <h3 className="ml-4   mb-2 " >
                  <p className='mb-2'>{t("fpThird.huellaTercerosTxtBanner")}
                  </p>
                </h3>}
                {!thirdPartyForm && <h3 className="ml-4   mb-2 " >
                  <p className='mb-2'>{t("footPrint.noThirdPartyContact")}
                  </p>
                </h3>}
              </div>
              <div className="flex justify-end" style={{ flex: "1" }}>
                <img src={folder} width={120}></img>
              </div>
            </div>
          </div>
        </button>
      }



      <div id="footprint-form-section" >
        <div className="" >
          <div className="flex justify-end">
            {isOpen ? (
              <button onClick={toggleAccordion} type="button" className="flex item-center gap-1">
                <span className="text-xs italic">  {t("footPrint.ocultar")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-up"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="var(--airco2-green-color)"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 15l6 -6l6 6" />
                </svg>
              </button>
            ) : (
              <button onClick={toggleAccordion} type="button" className="flex item-center">
                <span className="text-xs italic">  {t("footPrint.mostrar")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-down"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="var(--airco2-green-color)"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 9l6 6l6 -6" />
                </svg>
              </button>
            )}
          </div>
          {isOpen && (



            <form id="footprint-form" className='' onSubmit={handlerSubmit}>
              <div className='row justify-start'>

                {inputData.map((inputObj, i) => {
                  const input = inputObj.input;
                  let options = input.options;

                  //Filtrar comercializadoras por el countryId de la sede seleccionada
                  if (questBlock.data.id == 9 && input.inputOptions == "CONCEPT" && selectedOrgBuildingCountryId != undefined) {
                    options = options.filter(item => item.countryId == selectedOrgBuildingCountryId);
                  }

                  return (
                    <>
                      <div className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-3 text-xs`} key={input.id}
                        style={{ display: input.title === 'Teletrabajo' ? 'none' : 'block' }}>
                        <label className={`custom-width-md flex items-center m-0`} >
                          <span>{input.title}</span>
                          {inputObj.required && <span className=" pl-1 text-red-500">*</span>}

                          {input.id == 4 &&
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipDistancia}
                            >
                              <button onClick={handleModalDistanceCalculation} type="button" >
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-google-maps" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                  <path d="M6.428 12.494l7.314 -9.252" />
                                  <path d="M10.002 7.935l-2.937 -2.545" />
                                  <path d="M17.693 6.593l-8.336 9.979" />
                                  <path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z" />
                                </svg>
                              </button>
                            </OverlayTrigger>}

                          {input.id == 55 &&
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltipFecha}
                            >

                              <svg style={{ marginBottom: '-5px' }} xmlns="http://www.w3.org/2000/svg" className="pl-1 pb-2 icon icon-tabler icon-tabler-info-circle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                <path d="M12 9h.01" />
                                <path d="M11 12h1v4h1" />
                              </svg>

                            </OverlayTrigger>}


                          {/* Si es residuos, mostrar botón y poder activar vista de tabla para seleccionar la fuente de emisión con codigo LER  */}
                          {((questBlock.data.id == 56 || questBlock.data.id == 57|| questBlock.data.id == 72) && input.inputOptions == "CONCEPT") &&
                            <button type="button" onClick={() => handleConceptTableView(input.id)}>
                              <svg style={{ marginBottom: '-5px' }} xmlns="http://www.w3.org/2000/svg" width="23" height="23" className="pl-1 pb-2" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M12 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v8"></path>
                                <path d="M3 10h18"></path>
                                <path d="M10 3v18"></path>
                                <path d="M16 22l5 -5"></path>
                                <path d="M21 21.5v-4.5h-4.5"></path>
                              </svg>
                            </button>
                          }
                        </label>

                        <div>
                          {input.inputType === 'LIST' && 
                            (input.inputOptions == "SEDES" ? 
                            <div>
                              <Select
                                key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                name={`${input.inputOptions}`}
                                className='custom-width-md text-xs '
                                options={options.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                                styles={customStyles}
                                placeholder={`${t('footPrint.selecciona')}...`}
                                onChange={(e) => { handleSecondSelectDisplay(e); handleSelectChange(e, input.inputOptions) }}
                                defaultValue={() => handleTableViewDefaultValue(options, input.inputOptions)}
                                required />
                            </div>
                            :
                            <div>
                              <Select
                                key={selectKey}
                                name={`${input.inputOptions}`}
                                className='custom-width-md text-xs '
                                options={options.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                                styles={customStyles}
                                placeholder={`${t('footPrint.selecciona')}...`}
                                onChange={(e) => { handleSecondSelectDisplay(e); handleSelectChange(e, input.inputOptions) }}
                                defaultValue={() => handleTableViewDefaultValue(options, input.inputOptions)}
                                required />
                            </div>)}

                          {input.inputType === 'INT' &&
                            <div>
                              <input key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                name={`${input.inputOptions}`}
                                onChange={(e) => handleInputChange(e)}
                                defaultValue={defaultValue} type="number" step="1" min="1"
                                className='custom-width-md  text-xs'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }} required />
                            </div>
                          }

                          {input.inputType === 'DOUBLE' && input.inputOptions === "QUANTITY0" &&
                            <div>
                              <input
                                id="input-quantity0"
                                key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                name={`${input.inputOptions}`}
                                onChange={(e) => handleInputChange(e)}
                                defaultValue={defaultValue}
                                className={`custom-width-md  text-xs`}
                                style={{ borderColor: 'hsl(0, 0%, 80%)', backgroundColor: selectedOthers ? "transparent" : "#f0f0f0" }}
                                required={selectedOthers}
                                disabled={!selectedOthers}
                                type="number" step="0.01" min="0.01" />
                            </div>
                          }

                          {input.inputType === 'DOUBLE' && input.inputOptions !== "QUANTITY0" &&
                            <div className="flex quantity-expense-container">
                              {expenseQuestions.includes(questBlock.data.id) && 
                                <select
                                  value={selectedCurrency}
                                  className="quantity-expense-input text-xs text-left"
                                  style={{ borderColor: 'hsl(0, 0%, 80%)', width: '100px' }}
                                  onChange={changeCurrency}
                                >
                                  {currencies.map((curr) => (
                                    <option key={curr} value={curr}>{curr}</option>
                                  ))}
                                </select>
                              }
                              <input key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                id={`${input.id == 4 ? 'quantity-distance-input' : ''}`}
                                name={`${input.inputOptions}`}
                                onChange={(e) => handleInputChange(e)}
                                defaultValue={defaultValue}
                                className='custom-width-md  text-xs quantity-expense-input'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                required={inputObj.required ? true : false}
                                type="number" step="0.01" min="0.01" />
                            </div>
                          }

                          {input.inputType === 'STRING' &&
                            <div>
                              <input
                                key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                name={`${input.inputOptions}`}
                                onChange={(e) => handleInputChange(e)}
                                defaultValue={formSavingData[input.inputOptions.toLowerCase()] || defaultValue}
                                className='custom-width-md  text-xs'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                type="text"
                                required={inputObj.required ? true : false}

                              />
                            </div>
                          }


                          {input.inputType === 'DATE' &&
                            <div><input
                              key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                              name={`${input.inputOptions}`}
                              onChange={(e) => handleInputChange(e)}
                              defaultValue={defalultValueDate}
                              className='custom-width-md  text-xs'
                              style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                              type="date"
                              required={inputObj.required ? true : false}
                            />
                            </div>
                          }


                          {input.inputType === 'BOOL' &&
                            <div className='flex flex-inline'>
                              <input
                                key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                name={`${input.inputOptions}`}
                                onChange={(e) => handleInputChange(e)}
                                defaultValue={defaultValue}
                                className='custom-width-md text-xs'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                type="checkbox" />
                            </div>
                          }

                        </div>

                        {inputObj.description && <div>
                          <button
                            onClick={() => toggleInfo(input.id)}
                            className="text-xs text-gray-500 focus:outline-none"
                            type="button"
                            style={{ fontSize: '10px' }}
                          >
                            {mostrarInfo[input.id] ? t("footPrint.ocultarInfoInput") + ' ▲' : t("footPrint.mostrarInfoInput") + ' ▼'}
                          </button>
                          {mostrarInfo[input.id] && <div>
                            <label style={{ fontSize: '10px' }} className='m-0 italic'>
                              {inputObj.description}
                            </label>
                          </div>}
                        </div>}
                      </div>
                      {(i === 1 && selectedOption != null) &&
                        <div className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-3 text-xs`} key="div_combustible">
                          <label className='custom-width-md m-0'>
                            {selectedOption && selectedOption[0].subtitle && selectedOption.length > 0 ? selectedOption[0].subtitle : 'Tipo'}
                            <span className=" pl-1 text-red-500">*</span>

                          </label>
                          <Select
                            key={`input_combustible`}
                            name={`combustible`}
                            className='custom-width-md text-xs'
                            options={selectedOption.sort((a, b) => a.name.localeCompare(b.name)).map(combOption => ({ value: combOption.id, label: combOption.name }))}
                            styles={customStyles}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            value={selectedValue}
                            onChange={(e) => {
                              setSelectedValue(e);
                              handleSelectChangeSecondSelect(e, "concept");
                            }}
                            required>
                          </Select>
                        </div>
                      }
                    </>
                  );

                })}

              </div >


              <div className='flex justify-end'>
                <button type="submit" className={`btn-sm btn-green text-xs ${!showSendButton ? "hidden" : ""}`} disabled={submitBtnState}>{submitBtnState ? <Spinner animation="border" variant="light" size="sm" /> : "Guardar"}</button>
              </div>
            </form>

          )}




        </div>
      </div>

    </div >
  );
}

export default InputsComponent;