import React, { useState, useEffect } from 'react';
import bolsa1 from '../../../images/bolsa_metro.png';
import bolsa2 from '../../../images/bolsa_wong.png';

const ImageSlider = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    bolsa1,
    bolsa2
  ];

  const nextImage = () => {
    setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    // Automatically change the image every 5 seconds
    const intervalId = setInterval(() => {
      nextImage();
    }, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentImage]);


  return (
    <div className="" >
      <img
        src={images[currentImage]}
        alt={`Image ${currentImage + 1}`}
        className=""
      // width="350px"
      // height="1000px"
      />
      {/* <button className="ml-1 absolute top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full font-semibold" style={{left:"0px"}} onClick={prevImage}>
         &lt; 
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-caret-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#666666" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M14 6l-6 6l6 6v-12" />
</svg>
      </button>
      <button className="mr-1 absolute top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full font-semibold" style={{right:"0px"}} onClick={nextImage}>
        &gt;

      </button> */}
    </div>
  );
};

export default ImageSlider;