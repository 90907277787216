import axios from "axios";
import { BarController, ScatterController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "../../../../context/createcontext";

Chart.register(BarController,ScatterController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function DecarbonizationChart({ resume, plazo }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const canvas = useRef(null);
    const legend = useRef(null);
    const [t, i18n] = useTranslation("global");
    const currentYear = new Date().getFullYear();
    const [resumenMonthly, setResumenMonthly] =  useState({ data: undefined, isFetching: false });
    
    const scope1and2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2');
    const scope1and2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-light');
    const evolutionScope1and2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution');
    const evolutionScope1and2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution-light');

    const scope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3');
    const scope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-light');
    const evolutionscope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-evolution');
    const evolutionScope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-evolution-light');
    
    const colorRealScope12 = '#004488';
    const colorRealScope3 = '#cc7700';

    const scope1and2ColorSecondary1Bar = "#ffc477";
    const scope3ColorSecondary1Bar = "#88b2e0";
    const scope1and2ColorSecondary2Bar = "#ffe099";
    const scope3ColorSecondary2Bar = "#aad1f3";
    // const evolutionScope1and2ColorSecondary = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution');
    // const evolutionScope1and2LightColorSecondary = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scopes1and2-evolution-light');

    const fetchDescarbonizationScenario = async () => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}/${resume.id}/monthly`;
        try {
            const response = await axios.get(`${url}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            setResumenMonthly({ data: response?.data?.data, isFetching: true })

        } catch (exception) {
            setResumenMonthly({ data: undefined, isFetching: false });
            console.error(exception);
        }
    };

    console.log(resumenMonthly)

    useEffect(() => {
        if (!resume) return;

        const scenarioYears = Array.from({ length: resume.targetYear - resume.baseYear + 1 }, (_, i) => resume.baseYear + i);
        const indexCurrentYear = scenarioYears.findIndex((element) => element == currentYear);

        const yearlyScopes1_2ProgressionByAppliedReduction = calcularValoresAnuales(resume.tco2eScopes1and2, resume.currentScopes1and2, resume.baseYear, resume.targetYear);
        const yearlyScope3ProgressionByAppliedReduction = calcularValoresAnuales(resume.tco2eScope3, resume.currentScope3, resume.baseYear, resume.targetYear);

        const yearlyTargetTrendScopes1_2 = calcularValoresAnuales(resume.tco2eScopes1and2, resume.targetScopes1and2, resume.baseYear, resume.targetYear);
        const yearlyTargetTrendScope3 = calcularValoresAnuales(resume.tco2eScope3, resume.targetScope3, resume.baseYear, resume.targetYear);


        const uniqueYearTotalScopesList = resume.calculationTotalScopesList.reduce((acc, curr) => {
            // Busca si ya existe un objeto con el mismo año en el acumulador
            const existing = acc.find(item => item.year === curr.year);
        
            if (existing) {
                // Si existe, suma las propiedades
                existing.scope1 += curr.scope1;
                existing.scope2 += curr.scope2;
                existing.scope3 += curr.scope3;
            } else {
                // Si no existe, añade un nuevo objeto al acumulador
                acc.push({ ...curr });
            }
        
            return acc;
        }, []);

        let years;

        let yearlyDataScopes1and2 = [];
        let yearlyDataScope3 = [];

        let yearlyTrendDataScopes1and2 = [];
        let yearlyTrendDataScope3 = [];
        
        let monthlyDataScopes1and2PreviousCurrentYear = [];
        let monthlyDataScope3PreviousCurrentYear = [];
        let monthlyDataScopes1and2CurrentYear = [];
        let monthlyDataScope3CurrentYear = [];

        let legendMonthlyVierw = []

        let yearlyRealDataScopes1and2 = [];
        let yearlyRealdDataScope3 = [];

        switch (plazo) {
            case "Largo plazo":

                years = scenarioYears;
                
                yearlyDataScopes1and2 = yearlyScopes1_2ProgressionByAppliedReduction;
                yearlyDataScope3 = yearlyScope3ProgressionByAppliedReduction;

                yearlyTrendDataScopes1and2 = yearlyTargetTrendScopes1_2;
                yearlyTrendDataScope3 = yearlyTargetTrendScope3;

                if (uniqueYearTotalScopesList.length > 0) {
                    yearlyRealDataScopes1and2 = new Array(years.length);
                    yearlyRealdDataScope3 = new Array(years.length);

                    uniqueYearTotalScopesList.forEach((item, i) => {
                        let indexYear = years.findIndex((element) => element == item.year);
                        if (indexYear != -1) {
                            yearlyRealDataScopes1and2[indexYear] = item.scope1 + item.scope2;
                            yearlyRealdDataScope3[indexYear] =  item.scope3;
                        }
    
                    })

                }

                break;
            case "Medio plazo":
                years = [];
                years.push(scenarioYears[0]);
                scenarioYears.slice(indexCurrentYear, indexCurrentYear + 3).forEach((year) => years.push(year))

                yearlyDataScopes1and2.push(yearlyScopes1_2ProgressionByAppliedReduction[0]);
                yearlyDataScope3.push(yearlyScope3ProgressionByAppliedReduction[0]);
                yearlyTrendDataScopes1and2.push(yearlyTargetTrendScopes1_2[0]);
                yearlyTrendDataScope3.push(yearlyTargetTrendScope3[0]);

                yearlyScopes1_2ProgressionByAppliedReduction.slice(indexCurrentYear, indexCurrentYear + 3).forEach((x) => yearlyDataScopes1and2.push(x) );
                yearlyScope3ProgressionByAppliedReduction.slice(indexCurrentYear, indexCurrentYear + 3).forEach((x) => yearlyDataScope3.push(x));
                yearlyTargetTrendScopes1_2.slice(indexCurrentYear, indexCurrentYear + 3).map((x) => yearlyTrendDataScopes1and2.push(x));
                yearlyTargetTrendScope3.slice(indexCurrentYear, indexCurrentYear + 3).map((x) => yearlyTrendDataScope3.push(x));

                if (uniqueYearTotalScopesList.length > 0) {
                    yearlyRealDataScopes1and2 = new Array(years.length);
                    yearlyRealdDataScope3 = new Array(years.length);

                    uniqueYearTotalScopesList.forEach((item, i) => {
                        let indexYear = years.findIndex((element) => element == item.year);
                        if (indexYear != -1) {
                            yearlyRealDataScopes1and2[indexYear] = item.scope1 + item.scope2;
                            yearlyRealdDataScope3[indexYear] =  item.scope3;
                        }
    
                    })
                }

                break;

            case "Corto plazo":

                if (resumenMonthly.data == undefined) {
                    fetchDescarbonizationScenario();
                }
                
                years = [
                    "Enero", "Febrero", "Marzo", "Abril", 
                    "Mayo", "Junio", "Julio", "Agosto", 
                    "Septiembre", "Octubre", "Noviembre", "Diciembre"
                ];

                yearlyDataScopes1and2 = [resumenMonthly?.data?.tco2eScopes1and2January, resumenMonthly?.data?.tco2eScopes1and2February, resumenMonthly?.data?.tco2eScopes1and2March, resumenMonthly?.data?.tco2eScopes1and2April,
                    resumenMonthly?.data?.tco2eScopes1and2May, resumenMonthly?.data?.tco2eScopes1and2June, resumenMonthly?.data?.tco2eScopes1and2July, resumenMonthly?.data?.tco2eScopes1and2August,
                    resumenMonthly?.data?.tco2eScopes1and2September,  resumenMonthly?.data?.tco2eScopes1and2October,  resumenMonthly?.data?.tco2eScopes1and2November, resumenMonthly?.data?.tco2eScopes1and2December ];
                
                yearlyDataScope3 = [resumenMonthly?.data?.tco2eScope3January, resumenMonthly?.data?.tco2eScope3February, resumenMonthly?.data?.tco2eScope3March, resumenMonthly?.data?.tco2eScope3April,
                    resumenMonthly?.data?.tco2eScope3May, resumenMonthly?.data?.tco2eScope3June, resumenMonthly?.data?.tco2eScope3July, resumenMonthly?.data?.tco2eScope3August,
                    resumenMonthly?.data?.tco2eScope3September,  resumenMonthly?.data?.tco2eScope3October,  resumenMonthly?.data?.tco2eScope3November, resumenMonthly?.data?.tco2eScope3December ];

                    legendMonthlyVierw = [resume.baseYear, currentYear - 1, currentYear];

                if(currentYear - 1 == resume.baseYear){
                    legendMonthlyVierw = [resume.baseYear, currentYear];
                }

                monthlyDataScopes1and2PreviousCurrentYear = calcularProgresionMensual(yearlyScopes1_2ProgressionByAppliedReduction[indexCurrentYear - 1], yearlyScopes1_2ProgressionByAppliedReduction[indexCurrentYear], years.length);
                monthlyDataScope3PreviousCurrentYear = calcularProgresionMensual(yearlyScope3ProgressionByAppliedReduction[indexCurrentYear - 1], yearlyScope3ProgressionByAppliedReduction[indexCurrentYear], years.length);

                monthlyDataScopes1and2CurrentYear = calcularProgresionMensual(yearlyScopes1_2ProgressionByAppliedReduction[indexCurrentYear], yearlyScopes1_2ProgressionByAppliedReduction[indexCurrentYear+1], years.length);
                monthlyDataScope3CurrentYear = calcularProgresionMensual(yearlyScope3ProgressionByAppliedReduction[indexCurrentYear], yearlyScope3ProgressionByAppliedReduction[indexCurrentYear+1], years.length);

                break;
        }

        let data = {
            labels: years,
            datasets: [
                {
                    label: t("decarbonization.scopes1and2"),
                    data: yearlyDataScopes1and2,
                    backgroundColor: scope1and2Color,
                    hoverBackgroundColor: scope1and2LightColor,
                    stack: 'a',
                    borderWidth: 1,
                    order: 3,
                    fill: false,
                },
                {
                    label: t("decarbonization.scope3"),
                    data: yearlyDataScope3,
                    backgroundColor: scope3Color,
                    hoverBackgroundColor: scope3LightColor,
                    stack: 'b',
                    borderWidth: 1,
                    order: 3,
                    fill: false,
                },
                {
                    label: t("decarbonization.targetScopes1and2"),
                    data: yearlyTrendDataScopes1and2,
                    type: 'line',
                    borderColor: evolutionScope1and2Color,
                    backgroundColor: evolutionScope1and2LightColor,
                    yAxisID: 'y',
                    fill: false,
                    order: 2,
                    pointRadius: 1, 
                },
                {
                    label: t("decarbonization.targetscope3"),
                    data: yearlyTrendDataScope3,
                    type: 'line',
                    borderColor: evolutionscope3Color,
                    backgroundColor: evolutionScope3LightColor,
                    yAxisID: 'y',
                    fill: false,
                    order: 2,
                    pointRadius: 1, 
                },
                {
                    label: "Real " + t("decarbonization.scopes1and2"),
                    data: yearlyRealDataScopes1and2,
                    type: 'line',
                    borderColor: colorRealScope12,
                    backgroundColor: colorRealScope12,
                    yAxisID: 'y',
                    borderWidth: 1,
                    order: 2,
                    tension: 0.2,
                    pointRadius: 1, 
                },
                {
                    label: "Real " + t("decarbonization.scope3"),
                    data: yearlyRealdDataScope3,
                    type: 'line',
                    borderColor: colorRealScope3,
                    backgroundColor: colorRealScope3,
                    yAxisID: 'y',
                    borderWidth: 1,
                    order: 2,
                    tension: 0.2,
                    pointRadius: 1 
                }
            ],
        };

        if (monthlyDataScopes1and2PreviousCurrentYear.length > 0){
            console.log("dentro data 2")
        data = {
            labels: years,
            datasets: [
                {
                    label: t("decarbonization.scopes1and2") + " " + legendMonthlyVierw[0],
                    data: yearlyDataScopes1and2,
                    backgroundColor: scope1and2Color,
                    hoverBackgroundColor: scope1and2LightColor,
                    borderWidth: 1,
                    stack: 'a',
                    order: 1,
                    fill: false,
                },
                {
                    label: t("decarbonization.scope3") + " " +  legendMonthlyVierw[0],
                    data: yearlyDataScope3,
                    backgroundColor: scope3Color,
                    hoverBackgroundColor: scope3LightColor,
                    borderWidth: 1,
                    stack: 'a',
                    order: 2,
                    fill: false,
                },
                {
                    label: t("decarbonization.scopes1and2") + " " + legendMonthlyVierw[1],
                    data: monthlyDataScopes1and2PreviousCurrentYear,
                    backgroundColor: scope1and2ColorSecondary1Bar,
                    hoverBackgroundColor: scope1and2ColorSecondary1Bar,
                    borderWidth: 1,
                    stack: 'b',
                    order: 3,
                    fill: false,
                },
                {
                    label: t("decarbonization.scope3") + " " +  legendMonthlyVierw[1],
                    data: monthlyDataScope3PreviousCurrentYear,
                    backgroundColor: scope3ColorSecondary1Bar,
                    hoverBackgroundColor: scope3ColorSecondary1Bar,
                    stack: 'b',
                    borderWidth: 1,
                    order: 4,
                    fill: false,
                },                {
                    label: t("decarbonization.scopes1and2") + " " + legendMonthlyVierw[2],
                    data: monthlyDataScopes1and2CurrentYear,
                    backgroundColor: scope1and2ColorSecondary2Bar,
                    hoverBackgroundColor: scope1and2ColorSecondary2Bar,
                    borderWidth: 1,
                    stack: 'c',
                    order: 5,
                    fill: false,
                },
                {
                    label: t("decarbonization.scope3") + " " + legendMonthlyVierw[2],
                    data: monthlyDataScope3CurrentYear,
                    backgroundColor: scope3ColorSecondary2Bar,
                    hoverBackgroundColor: scope3ColorSecondary2Bar,
                    borderWidth: 1,
                    order: 6,
                    stack: 'c',
                    fill: false,
                },
            ],
        };
    }

        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars
        switch (lng) {
            case "en":
                moment.locale("en");
                break;
            case "pt":
                moment.locale("pt");
                break;
            case "gl":
                moment.locale("gl");
                break;
            default:
                moment.locale("es");
                break;
        }
        const chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },

                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const dataset = context.dataset;
                                const valorFormateado = `${formatThousands(context.parsed.y)} kgCO₂e`;
                                return `${dataset.label}: ${valorFormateado}`;
                            },
                        },
                    },

                    legend: {
                        display: true,
                    },
                },
                // maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'category',
                    },
                    y: {
                        stacked: false,
                        beginAtZero: true
                    }
                }
            }

        });

        return () => chart.destroy();
        // eslint-disable-next-line
    }, [lng, resume, plazo, resumenMonthly.data]);



    function calcularValoresAnuales(valorBase, reduccionTotal, anioInicial, anioFinal) {
        const numAnios = anioFinal - anioInicial;
        const tasaReduccionAnual = 1 - Math.pow((1 - (reduccionTotal / 100)), 1 / numAnios);

        const valoresAnuales = [valorBase]; // Iniciar con el valor base

        for (let i = 1; i <= numAnios; i++) {
            const valorAnterior = valoresAnuales[i - 1];
            const valorActual = valorAnterior * (1 - tasaReduccionAnual);
            valoresAnuales.push(valorActual);
        }

        return valoresAnuales;
    }
    function calcularProgresionMensual(valorAnualInicio, valorAnualFin, totalValores ) {

        const valoresMensuales = [];

        const division = (valorAnualFin - valorAnualInicio) / (totalValores - 1);
        
        for (let i = 0; i < totalValores; i++) {
            valoresMensuales.push( valorAnualInicio + (i * division));
        }
    
        return valoresMensuales;
    }
    return (
        <React.Fragment>
            {(!resume || (plazo == "Corto plazo" && !resumenMonthly.data)) && <div className="text-center"><Spinner className='' /></div>}
            {!canvas !== null && resume && <canvas ref={canvas} style={{ maxHeight: "350px" }}></canvas>}
        </React.Fragment>
    );


}